import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import consumer_air_ticket_privateDetail from '@/lib/data-service/haolv-default/consumer_air_ticket_privateDetail'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_journey_planeTicketcalculatePrice from '@/lib/data-service/haolv-default/consumer_journey_planeTicketcalculatePrice'
import consumer_journey_personalCreateOrder from '@/lib/data-service/haolv-default/consumer_journey_personalCreateOrder'
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import TravelerListForPrivateReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-private-reserve/1.0.0/index.vue'
import InsureChoice from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/insure-choice/1.0.2/index.vue'
import EnjoyMoreProduct from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/enjoy-more-product/1.0.0/index.vue'

import moment from "moment";
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import PolicyTypeLabel from '@/page/admin/travel-book/flight/component/policyTypeLabel/index.vue'
import consumer_flight_search_queryPlaneNameByCodes
    from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
import consumer_flight_search_returnStipulatesRule
    from "@/lib/data-service/haolv-default/consumer_flight_search_returnStipulatesRule";
import consumer_flight_order_flightsStop from "@/lib/common-service/consumer_flight_order_flightsStop";
import ChangeReturnClausePopup from '@/page/admin/travel-book/flight/component/changeReturnClausePopup/1.0.0/index.vue';
export default {
    data() {
        return {
            equityData: {},
            flightMore: false,
            loading: false,
            orderNo: '',
            evectionType: '',
            flightType: 1,
            evectionParams: {
                orderNo: '',
                linkman: '',
                linkmanPhone: '',
                linkmanSms: 0, // 联系人短信: 0:不发送;1:发送 默认0
                frequentIds: [],
                bookingSms: 0, // 出行人短信: 0:不发送;1:发送 默认0
            },
            evectionInfo: [],

            searchTemp: '',
            intervalNum: 0,

            priceResult: {},

            ruleShow: false,
            ruleLoading: false,
            ruleText: [], // 退改规则
            productTag: 0,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 5,
            returnSetInterval: '',

            insuranceIdArr: [],

            hasAgreementPricePro: false, // 是否含有协议价产品
            dialogError3Visible: false,
            tmcSnailValuations: [],
        }
    },
    components: {ChangeClausePopup, TravelerListForPrivateReserve, InsureChoice, PolicyTypeLabel, ChangeReturnClausePopup, EnjoyMoreProduct},
    created() {
    },
    mounted() {
    },
    async activated() {
        this.intervalNum = 0;
        this.orderNo = this.$route.query.orderNo;
        this.evectionParams.orderNo = this.$route.query.orderNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.$refs.aEnjoyMoreProduct.init({
            //订单号
            orderNo: this.orderNo,
        });
        await this.getEvectionInfo();
        // this.showThisRuleList(false)
        this.getAllPrice(); // 获取价格信息
        this.$refs['travelerListForReserve'].init({
            businessType: 1,
            startTime: this.evectionInfo[0].depDate,
            flightTmcSnailValuations: this.tmcSnailValuations,
        });
        this.$nextTick(() => {
            this.initInsure()
        });
        this.setSearchReact()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        changeEnjoyMore(data) {
            this.equityData = data;
            this.getAllPrice();
        },
        async getEvectionInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_air_ticket_privateDetail({orderNo: this.orderNo}));
            if (err) {
                this.loading = false;
                return
            }
            this.loading = false;
            res.datas.forEach(value => {
                this.tmcSnailValuations = value.tmcSnailValuations ?? [];
                if (value.policyType === 'QCYX') {
                    this.hasAgreementPricePro = true;
                }
                let stopData = value.stopData.stopInfos;
                if (!stopData) {
                    return
                }
                stopData.forEach((value1,index1)=>{
                    if (index1 === 1) {
                        let depTimeArr = value1.depTime.split(':');
                        let depTime = parseInt(depTimeArr[0]) * 60 + parseInt(depTimeArr[1]);
                        let arrTimeArr = value1.arrTime.split(':');
                        let arrTime = parseInt(arrTimeArr[0]) * 60 + parseInt(arrTimeArr[1]);
                        let showTime = depTime - arrTime;
                        value1.showTime = showTime;
                    }
                })
                value.stopData = stopData;
            })
            this.evectionInfo = res.datas;
            /*let getStopInfo = [];
            this.evectionInfo.forEach(value1 => {
                if (value1.stopNum > 0) {
                    const params = {
                        "rqData": {
                            "date": value1.depDate,
                            "flightNo": value1.isShare == 1 ? value1.shareFlightNo : value1.flightNo,
                            'depCityCode': value1.depCityCode,
                            'arrCityCode': value1.arrCityCode,
                            'arrTime': value1.arrTime,
                            'deptTime': value1.depTime,
                            'depAirCode': value1.depCityPortCode,
                            'arrAirCode': value1.arrCityPortCode,
                        }
                    }
                    getStopInfo.push(consumer_flight_order_flightsStop(params));
                }
            })
            Promise.all(getStopInfo).then(resList=>{
                this.evectionInfo.forEach(value1 => {
                    value1.stopData = [];
                    resList.forEach(value2 => {
                        console.log(value2.datas.flightNo, value1.flightNo, value2.datas.date, value1.depDate, value2.datas.stopInfos.length)
                        if ((value2.datas.flightNo === value1.flightNo || value2.datas.flightNo === value1.shareFlightNo) && value2.datas.date === value1.depDate && value2.datas.stopInfos.length > 0) {
                            let stopInfo = value2.datas.stopInfos[1];
                            let depTimeArr = stopInfo.depTime.split(':');
                            let depTime = parseInt(depTimeArr[0]) * 60 + parseInt(depTimeArr[1]);
                            let arrTimeArr = stopInfo.arrTime.split(':');
                            let arrTime = parseInt(arrTimeArr[0]) * 60 + parseInt(arrTimeArr[1]);
                            let showTime = depTime - arrTime;
                            stopInfo.showTime = showTime;
                            value1.stopData.push(stopInfo);
                        }
                    })
                })
            })
            this.$forceUpdate();*/
             //餐食
            this.queryMeals();
            //机型
            this.queryPlaneNames();
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    clearInterval(this.searchTemp)
                    this.$message({
                        type: "warning",
                        message: '页面停留时间超过30分钟，请您重新搜索航班'
                    })
                    this.intervalNum = 0
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                }
            }, 1000)
        },
        // 展示退改规则弹窗
        showThisRuleList(val) {
            this.ruleShow = val
            this.ruleLoading = true
            this.ruleText = {};
            let params = {
                rqData: {
                    cabinCode: this.evectionInfo.cabin,
                    flightKey: this.evectionInfo.flightKey,
                    flightNo: this.evectionInfo.flightNo,
                    policyId: this.evectionInfo.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        // 获取订单所有价格
        async getAllPrice() {
            let params = {
                //evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                headCounts: this.evectionParams.frequentIds.length,
                packageCodes: this.insuranceIdArr,
                productIds: this.equityData == null ? [] : [this.equityData.productId],
            };
            let res = await consumer_journey_planeTicketcalculatePrice(params);
            let priceResult = res.datas;
            let flightRebatePrice = 0;
            let flightAirportTaxAndFuelTax = 0;
            let flightSubtotal = 0;
            if (priceResult.flightCalculates) {
                priceResult.flightCalculates.forEach(value => {
                    flightRebatePrice += parseFloat(value.rebatePrice);
                    flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax + parseFloat(value.airportTax) + parseFloat(value.fuelTax)
                    flightSubtotal += parseFloat(value.subtotal)
                })
            }
            priceResult.flightRebatePrice = flightRebatePrice;
            priceResult.flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax;
            priceResult.flightSubtotal = flightSubtotal;
            this.priceResult = priceResult
        },
        getTravelerInfo(param) {
            console.log('param', param);
            this.evectionParams.frequentIds = param.userIdList;
            this.getAllPrice()
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    this.$confirm('该订单支付后预计30分钟内出票，是否继续预订？', '温馨提示',{
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        // 最终创建订单
        async createOrder() {
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            let timeTipsRes = await this.timeLongTips(this.$route.query.ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }
            this.loading = true
            let travelerInfo = this.$refs['travelerListForReserve'].getTravelerInfo();
            console.log(travelerInfo);
            this.evectionParams.linkman = travelerInfo.linkman
            this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone
            this.evectionParams.bookingSms = travelerInfo.smsType
            this.evectionParams.linkmanSms = travelerInfo.linkmanSms
            this.evectionParams.frequentIds = travelerInfo.userIdList
            this.evectionParams.userList = travelerInfo.userList
            let hasChild = false;
            let hasDifferentAge = false;
            let hasDifferentAgeNameList = [];
            console.log(this.evectionParams.userList);
            this.evectionParams.userList.forEach(item=>{
                if (item.age < 13) {
                    hasChild = true;
                }
                if (item.age < 22 || item.age > 65) {
                    hasDifferentAge = true;
                    hasDifferentAgeNameList.push(item.frequentPassengerName || item.userName);
                }
            })
            if (hasChild) {
                this.loading = false;
                this.$message({
                    type: 'warning',
                    message: '暂不支持在线预订儿童票'
                })
                return;
            }
            if (this.hasAgreementPricePro && hasDifferentAge) {
                this.errorText = `协议价仅限乘客年龄22至65周岁预订，乘客${hasDifferentAgeNameList.join('，')}年龄不符合要求，请选择其他非协议价预订`;
                this.dialogError3Visible = true;
                return;
            }
            // this.evectionParams.frequentIds = [1383] // 虚拟id
            if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
                this.loading = false
                this.$message({
                    type: 'warning',
                    message: '请先填写联系人'
                })
                return
            }
            if (this.evectionParams.frequentIds.length === 0) {
                this.loading = false
                this.$message({
                    type: 'warning',
                    message: '请先添加乘机人'
                })
                return
            }
            let policyText = '';
            this.$refs['travelerListForReserve'].form.userList.forEach((item,index) => {
                console.log(item);
                let box = this.$refs['travelerListForReserve'].policyRestrictionsText(item);
                if (box && box.length != 0) {
                    box.forEach((BItem,BIndex) => {
                        policyText = `<p>${policyText}${BItem},</p>`;
                    });
                    policyText = `${policyText}<div>当前出行人<span style="color: red;">${item.userName}</span>不符合条件;</div>`;
                }
            });
            let policyNumberPeople = this.$refs['travelerListForReserve'].policyNumberPeople;
            if (policyNumberPeople && policyNumberPeople.length != 0) {
                policyNumberPeople.forEach((PItem,PIndex) => {
                    policyText = `<p>${policyText}${PItem};</p>`;
                })
            }
            if (policyText != '') {
                this.$alert(`${policyText}`, '温馨提示', {
                    dangerouslyUseHTMLString: true
                });
                this.loading = false;
                return;
            }

            clearInterval(this.searchTemp);
            let evectionParams = JSON.parse(JSON.stringify(this.evectionParams));
            evectionParams.enable_error_alert = false; // 临时隐藏
            evectionParams.orderClient = 2;
            evectionParams.equityProductId = this.equityData == null ? null : this.equityData.productId;
            evectionParams.equityProductPrice = this.equityData == null ? null : this.equityData.farePat;
            let [err, result] = await awaitWrap(consumer_journey_personalCreateOrder(evectionParams));
            if (err) {
                this.setSearchReact();
                // 200017 下单失败!机票订票失败:该航班变价，请重新搜索！
                this.loading = false;
                let orderResultList = err.data.datas ? err.data.datas.orderResultList : [];
                let errorCode = err.data.code;
                this.errorText = err.data.msg;
                if (errorCode === '200017' || errorCode === '1000000') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 5;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.returnToSearch()
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
                return
            }
            let orderResult = result.datas;
            let canSubmit = true;
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                }
            });
            if (canSubmit) {
                this.$refs.aInsureChoice.submit().then(res => {
                    this.loading = false;
                    this.$router.push({
                        name: 'admin-personal-flight-submit-order',
                        query: {
                            orderNo: this.orderNo
                        }
                    })
                }).catch(() => {
                    this.loading = false
                });
            } else {
                this.setSearchReact();
                this.loading = false;
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },
        returnToSearch() {
            // this.detailFlight
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            this.$router.go(-1)
        },
        toCabinList() {
            this.dialogError3Visible = false;
            this.$router.go(-1);
        },
        changeFlight() {
            const key = 'params';
            const queryParams = {
                fromStationCode: this.evectionInfo.depAirportCode,
                fromStationName: this.evectionInfo.depAirportName,
                toStationCode: this.evectionInfo.arrAirportCode,
                toStationName: this.evectionInfo.arrAirportName,
                startDate: this.evectionInfo.depDate,
                endDate: this.flightType === 2 ? this.evectionInfo.arrDate : '',
                flightType: this.flightType,
            }
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    params: encrypted,
                    evectionNo: '',
                    evectionType: 1, // 1是个人出差，2是出差单出差
                    evectionRequired: 0,
                }
            })
        },
        /*setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },*/
        initInsure() {
            this.$refs.aInsureChoice.init({
                mock_data:{
                    //是否使用随机数据--true/false
                    enable: false,
                },
                //业务类型--1：机票/2：火车票
                businessType: 1,
                //订单号
                orderNo: this.orderNo,
                evectionType: 1,
                flightType: this.flightType,
            });
        },
        changeInsure(data) {
            this.insuranceIdArr = data.id_list
            this.getAllPrice()
        },
        queryPlaneNames(){
            const planeTypes = [];
            this.evectionInfo.forEach(value => {
                planeTypes.push(value.planeType);
            })

            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);

                let planeMap = {};
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap[i.planeType] = i.planeName;
                    })
                }
                console.log(planeMap);
                this.evectionInfo.forEach(value => {
                    console.log(value, value.planeType, planeMap[value.planeType]);
                    value.planeName = planeMap[value.planeType]
                })
                this.$forceUpdate();


            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
        queryMeals() {
            let getQueryMeals = [];
            this.evectionInfo.forEach(value => {
                let param = {
                    flightNo: value.flightNo,
                    deptTime: value.depTime,
                    arrTime: value.arrTime
                };
                getQueryMeals.push(consumer_flight_search_queryMeals(param));
            })
            Promise.all(getQueryMeals).then((res)=>{
                res.forEach((value, index) => {
                    this.evectionInfo[index].hasMeals = value.datas? '有餐食':'无餐食';
                    this.$forceUpdate();
                })
            }).catch(function(reason){
                console.log('出错了',reason)
            });


        },
        showRulePopup() {
            // 调接口打开规则弹窗
            this.ruleLoading = true;
            this.ruleText = [];
            this.ruleShow = true;
            let params = {
                rqData: []
            };
            this.evectionInfo.forEach(value => {
                const rqData = {
                    cabinCode: value.cabin,
                    flightKey: value.flightKey,
                    flightNo: value.flightNo,
                    policyId: value.policyId
                };
                params.rqData.push(rqData);
                this.productTag = value.productTag;
            })
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                this.ruleLoading = false;
                let ruleText = res.datas.rsData || [];
                this.evectionInfo.forEach(value=>{
                    ruleText.forEach(value1 => {
                        if (value1.policyId === value.policyId) {
                            value1.productTag = value.productTag;
                            value1.depCityName = value.depCityName;
                            value1.arrCityName = value.arrCityName;
                        }
                    });
                })
                this.ruleText = ruleText;
                console.log(this.ruleText);
            }).catch(()=>{
                this.ruleLoading = false;
            })
        },
    }
}
