import ChangeClause from '@/page/admin/travel-book/flight/component/changeClause/1.0.1/index.vue'
import moment from "moment";
export default {
    data() {
        return {
            active: 0,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        ruleTextList: {
            type: Array,
            default: () => []
        },
        showProductRule: {
            type: Boolean,
            default: true
        },
        appendToBody: {
            type: Boolean,
            default: false
        },
        flightType: {
            type: Number,
            default: 1,
        },
        showLuggage: {
            type: Boolean,
            default: true,
        },
        productTag: {
            type: Number,
            default: 0,
        },
    },
    components: {ChangeClause},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal) {
                this.active = 0;
            }
        }
    },
    computed: {
        ruleShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        ruleLoading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        }
    },
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        closeRuleShow() {
            this.ruleShow = false
        },
        opened() {},
        changeTab(index) {
            this.active = index;
        }
    }
}
