import EvectionForm from '@/component/evectionForm/import_latest_version_module'   // 因公出行信息显示组件
import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue";
import TravelerListForReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve/1.1.0/index.vue'//选择出行人组件
import ViolationResultBox from "@/page/admin/travel-book/flight/component/violationResultBox/index.vue";
import BackBtn from '@/component/backBtn/index.vue'
import InsureChoice from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/insure-choice/1.0.2/index.vue'
import PolicyTypeLabel from '@/page/admin/travel-book/flight/component/policyTypeLabel/index.vue'
import ReserveProcessBox from '@/component/reserve-process-box/1.0.0/index.vue'
import ChangeReturnClausePopup from '@/page/admin/travel-book/flight/component/changeReturnClausePopup/1.0.0/index.vue';
import EnjoyMoreProduct from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/enjoy-more-product/1.0.0/index.vue'

import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import moment from 'moment'

import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_journey_planeTicketcalculatePrice from '@/lib/data-service/haolv-default/consumer_journey_planeTicketcalculatePrice' // 计算价格
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_journey_unityCreatePlaneTicket from '@/lib/data-service/haolv-default/consumer_journey_unityCreatePlaneTicket' // 提交订单
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop' // 获取经停信息
import consumer_web_policyConfig_flighttravelPolicyTips from '@/lib/data-service/haolv-default/consumer_web_policyConfig_flighttravelPolicyTips'
import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_addReserveApply from '@/lib/data-service/haolv-default/consumer_apply_addReserveApply'
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
import consumer_payment_payReminder from '@/lib/data-service/haolv-default/consumer_payment_payReminder'
import consumer_insurance_checkFreeProduct from '@/lib/data-service/haolv-default/consumer_insurance_checkFreeProduct'
import consumer_flight_search_queryPlaneNameByCodes from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
import consumer_flight_search_returnStipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_returnStipulatesRule'
export default {
    data() {
        return {
            equityData: {},
            loading: false,
            loadingText: '',
            orderFreeApprove: false,
            flightMore: false,

            ruleText: [],
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
            productTag: 0,

            flightType: 1, // 航程类型 1单程 2往返

            evectionNo: '',
            orderNo: '',
            evectionType: 2,
            redirectType: '',
            evectionRequired: 0,
            workTravelType: '',  // 因公出差的类型， 1是有选出差单，2是没有
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            evectionInfo: {},
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                partnerEctionId: '',
            },
            evectionFormParams: {},
            priceResult: {},
            userInfo: {},
            flightList: [],
            tableData: [],

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 5,
            returnSetInterval: '',

            violationResults: [],

            searchTemp: '',
            intervalNum: 0,

            insuranceIdArr: [],

            hasNodeList: false,

            paymentPage: 0, // 是否弹窗 0.不弹 1.弹

            chooseInsureDialogVisible: false,
            nowTime: '',
            hasFreeInsureProduct: false,

            tipsHide: false,

            hasAgreementPricePro: false, // 是否含有协议价产品
            dialogError3Visible: false,
            tmcSnailValuations: [],
        }
    },
    components: {
        EvectionForm,
        NewChangeClause,
        TravelerListForReserve,
        ViolationResultBox,
        BackBtn,
        InsureChoice,
        EnjoyMoreProduct,
        PolicyTypeLabel,
        ReserveProcessBox,
        ChangeReturnClausePopup
    },
    created() {
    },
    mounted() {
        this.getPayRemider();
    },
    async activated() {
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo;
        this.orderNo = this.$route.query.orderNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.evectionRequired = this.$route.query.evectionRequired === undefined ? 0 : parseInt(this.$route.query.evectionRequired);
        this.workTravelType = this.$route.query.workTravelType === undefined ? '' : parseInt(this.$route.query.workTravelType);

        this.intervalNum = 0;
        await this.getOrderFreeApprove();
        await this.refundChangePolicyGet();
        await this.getUserInfo(); // 获取个人资料
        await this.getEvectionInfo();
        this.getBookingTip();//获取预订提示和相关限制
        this.getAllPrice(); // 获取价格信息
        this.getCheckExcess();

        let userIdList = [];
        this.evectionParams.travelUserList.forEach(value => {
            userIdList.push(value.userId)
        });
        this.$refs['travelerListForReserve'].init({
            situation: '100',
            businessType: 1,
            userIdList: userIdList,
            startTime: this.flightList[0].segmentList[0].depDate,
            flightTmcSnailValuations: this.tmcSnailValuations,
        });
        if (this.businessRuleControl === 2 && this.orderFreeApprove && this.$store.state.workflow === '2') {
            // 流程
            this.$refs['reserveProcessBox'].init().then(res => {
                this.hasNodeList = res
            })
        }
        this.$refs.aEnjoyMoreProduct.init({
            //订单号
            orderNo: this.orderNo,
        });
        this.$nextTick(() => {
            this.initInsure()
        });

        this.setSearchReact();
        // 获取是否含赠送保险
        this.checkFreeProduct();
    },
    deactivated() {
        clearInterval(this.searchTemp)
    },
    destroyed() {
        clearInterval(this.searchTemp)
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        // 获取出差信息
        async getEvectionInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, orderNo: this.orderNo, pageType: 1, personType: 2}))
            this.loading = false;
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            this.tmcSnailValuations = [];
            this.evectionInfo.flightDetail.orderList[0].segmentList.forEach((SItem,SIndex) => {
                this.tmcSnailValuations.push(SItem.tmcSnailValuations);
            });
            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: res.datas.reason,
                travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                travelUser: [],
                partnerEctionId: res.datas.partnerEctionId
            };
            this.evectionFormParams = {
                reason: this.evectionParams.reason,
                travelUserList: this.evectionParams.travelUserList,
                feeAffiliationType: this.evectionParams.feeAffiliationType,
                feeAffiliationName: this.evectionParams.feeAffiliationName,
                feeAffiliationId: this.evectionParams.feeAffiliationId,
                partnerEctionId: this.evectionParams.partnerEctionId || this.evectionParams.evectionNo,
                centerCode: '',
                projectCode: '',
                projectName: '',
                companyId: 0,
            };

            let userIdList = [];
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.userId)
            });

            // 获取机票行程
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : [];
            let segmentList = [];
            let getStopInfo = [];
            flightList.forEach(value => {
                value.segmentList.forEach(value1 => {
                    segmentList.push(value1);
                    value1.loading = false;
                    value1.ruleText = [];
                    value1.luggage = '';
                    if (value1.stopNum > 0) {
                        const params = {
                            "rqData": {
                                "date": value1.depDate,
                                "flightNo": value1.isShare == 1 ? value1.shareFlightNo : value1.flightNo,
                                'depCityCode': value1.depCityCode,
                                'arrCityCode': value1.arrCityCode,
                                'arrTime': value1.arrTime,
                                'deptTime': value1.depTime,
                                'depAirCode': value1.depCityPortCode,
                                'arrAirCode': value1.arrCityPortCode,
                            }
                        };
                        getStopInfo.push(consumer_flight_order_flightsStop(params));
                    }

                    if (value1.policyType === 'QCYX') {
                        this.hasAgreementPricePro = true;
                    }
                })
            });
            this.flightType = segmentList.length > 1 ? 2 : 1;
            this.flightList = flightList;
            Promise.all(getStopInfo).then(resList=>{
                this.flightList.forEach(value => {
                    value.segmentList.forEach(value1 => {
                        value1.stopData = [];
                        resList.forEach(value2 => {
                            console.log(value2.datas.flightNo, value1.flightNo, value2.datas.date, value1.depDate, value2.datas.stopInfos.length)
                            if ((value2.datas.flightNo === value1.flightNo || value2.datas.flightNo === value1.shareFlightNo) && value2.datas.date === value1.depDate && value2.datas.stopInfos.length > 0) {
                                let stopInfo = value2.datas.stopInfos[1];
                                let depTimeArr = stopInfo.depTime.split(':');
                                let depTime = parseInt(depTimeArr[0]) * 60 + parseInt(depTimeArr[1]);
                                let arrTimeArr = stopInfo.arrTime.split(':');
                                let arrTime = parseInt(arrTimeArr[0]) * 60 + parseInt(arrTimeArr[1]);
                                let showTime = depTime - arrTime;
                                stopInfo.showTime = showTime;
                                value1.stopData.push(stopInfo);
                            }
                        })
                    })
                })
            });
            this.$forceUpdate();

            this.$refs.evectionForm.init({
                oaNo: this.evectionInfo.partnerEctionId,
                startDate: this.flightList[0].segmentList[0].depDate,
                userId: userIdList,
                businessType: 1,
                companyId: this.userInfo.companyId,
            });

            console.log('11111------------');
            console.log(this.flightList);
            if (this.flightType === 1) {
                this.flightMore = true;
            }
            //机型描述
            this.queryPlaneNames();
            //餐食
            this.queryMeals();
        },
        evectionChange(val) {
            this.evectionParams = {...this.evectionParams, ...val}
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            //this.bookUpperBound = res.datas.bookUpperBound;
            //this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            //this.unLimitedJourney = res.datas.unLimitedJourney;
            //this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        async getUserInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(get_user_info());
            this.loading = false;
            this.userInfo = res.datas
        },
        getStopMsg(val, index, segmentIndex) {
            console.log(val);
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.isShare == 1 ? val.shareFlightNo : val.flightNo,
                    'depCityCode': val.depCityCode,
                    'arrCityCode': val.arrCityCode,
                    'arrTime': val.arrTime,
                    'deptTime': val.depTime,
                    'depAirCode': val.depCityPortCode,
                    'arrAirCode': val.arrCityPortCode,
                }
            };
            // val.stopLoad = true
            this.flightList[index].segmentList[segmentIndex].stopLoad = true;
            let flightsList = JSON.parse(JSON.stringify(this.flightList));
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentList[segmentIndex].stopData = res.datas;
                flightsList[index].segmentList[segmentIndex].stopLoad = false;
                this.flightList = flightsList
            }).catch(() => {
                flightsList[index].segmentList[segmentIndex].stopLoad = false;
                this.flightList = flightsList
            })
        },
        showThisRuleList(fIndex, index, val, item) {
            let orangeFlightList = JSON.parse(JSON.stringify(this.flightList));
            orangeFlightList[fIndex].segmentList[index].loading = true;
            this.flightList = orangeFlightList;
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo,
                dateTime: `${val.depDate} ${val.depTime}`,
                orderNo: item.orderNo,
                segmentNo: val.segmentNo
            };
            let data = {
                rqData: {
                    "arrCityPortCode": val.arrCityCode,
                    "cabinCode": val.cabinCode,
                    "depCityPortCode": val.depCityCode,
                    "depDate": val.depDate,
                    "flightNo": val.flightNo,
                    "flightProductType": val.flightProductType,
                    "flightType": item.flightType,
                    "psgType": item.psgType,
                    extData: '',
                    policyId: val.policyId,
                    businessExt: this.evectionInfo.businessExt
                }
            };

            let ruleText = [];
            let luggage = '';
            consumer_flight_search_stipulates(data).then(res => {
                if (res.datas.rsData) {
                    let refRule = res.datas.rsData.refRule;
                    let chaRule = res.datas.rsData.chaRule;
                    let refRuleTitArr = [];
                    let chaRuleTitArr = [];
                    let refRuleArr = [];
                    let chaRuleArr = [];
                    if (refRule && chaRule) {
                        refRule = refRule.rules;
                        chaRule = chaRule.rules;
                        refRule.forEach(value => {
                            value.type = "refRule";
                            if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                                refRuleTitArr.push(value.ruleTime);
                                refRuleArr.push(value);
                            }
                        });
                        chaRule.forEach(value => {
                            value.type = "chaRule";
                            if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                                chaRuleTitArr.push(value.ruleTime);
                                chaRuleArr.push(value);
                            }
                        });
                        refRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        });
                        chaRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        });
                        // refRule.map(item => (item.type = "refRule"));
                        // chaRule.map(item => (item.type = "chaRule"));
                        ruleText = [refRuleArr, chaRuleArr];
                    }
                    luggage = res.datas.rsData.luggage
                } else {
                    ruleText = [];
                    luggage = ''
                }

                let flightList = JSON.parse(JSON.stringify(this.flightList));
                flightList[fIndex].segmentList[index].ruleText = ruleText;
                flightList[fIndex].segmentList[index].luggage = luggage;
                flightList[fIndex].segmentList[index].loading = false;
                this.flightList = flightList;
            })
        },

        //获取预订提示及相关限制
        getBookingTip() {
            this.ruleText = [];
            let params = {
                rqData: []
            };
            this.flightList.forEach(value => {
                value.segmentList.forEach(value1=>{
                    const rqData = {
                        cabinCode: value1.cabinCode,
                        flightKey: value1.flightKey,
                        flightNo: value1.flightNo,
                        policyId: value1.policyId
                    };
                    params.rqData.push(rqData);
                })
            });
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                let ruleText = res.datas.rsData || [];
                this.flightList.forEach(value=>{
                    value.segmentList.forEach(value2=>{
                        ruleText.forEach(value1 => {
                            if (value1.policyId === value2.policyId) {
                                value1.depCityName = value2.depCityName;
                                value1.arrCityName = value2.arrCityName;
                            }
                        });
                    })
                });
                this.ruleText = ruleText;
            })
        },

        // 获取订单所有价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                headCounts: this.evectionType === 2 ? this.evectionParams.travelUserList.length : this.evectionParams.travelUser.length,
                packageCodes: this.insuranceIdArr,
                productIds: this.equityData == null ? [] : [this.equityData.productId],
            };
            let res = await consumer_journey_planeTicketcalculatePrice(params);
            let priceResult = res.datas;
            let flightRebatePrice = 0;
            let flightAirportTaxAndFuelTax = 0;
            let flightSubtotal = 0;
            if (priceResult.flightCalculates) {
                priceResult.flightCalculates.forEach(value => {
                    flightRebatePrice += parseFloat(value.rebatePrice);
                    flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax + parseFloat(value.airportTax) + parseFloat(value.fuelTax);
                    flightSubtotal += parseFloat(value.subtotal)
                })
            }
            priceResult.flightRebatePrice = flightRebatePrice;
            priceResult.flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax;
            priceResult.flightSubtotal = flightSubtotal;
            this.priceResult = priceResult
        },
        getCheckExcess() {
            this.flightList.forEach(value => {
                let params = {
                    "evectionNo": this.evectionNo,
                    "orderNo": this.orderNo,
                };
                consumer_web_policyConfig_flighttravelPolicyTips(params).then(res => {
                    this.violationResults = res.datas || []
                })
            })
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    this.$confirm('该订单支付后预计30分钟内出票，是否继续预订？', '温馨提示',{
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        productTipsShow() {
            return new Promise(resolve => {
                if (this.priceResult.bookingEquityProductInfos && this.priceResult.bookingEquityProductInfos.length > 0) {
                    if (this.priceResult.bookingEquityProductInfos[0].payType == 2) {
                        this.$confirm('定制服务与机票产品的报销凭证将一并开具给公司，个人不可开具发票', '温馨提示', {
                            confirmButtonText: '继续预订',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            resolve(true)
                        }).catch(() => {
                            resolve(false)
                        });
                    } else {
                        resolve(true)
                    }
                } else {
                    resolve(true)
                }
            })
        },
        payTipsShow() {
            return new Promise(resolve => {
                if (this.paymentPage === 1) {
                    // 弹窗
                    this.$confirm('因公出行订单费用将由企业账户支付', '温馨提示', {
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消预订',
                        type: 'warning'
                    }).then(() => {
                        resolve(true);
                    }).catch(() => {
                        resolve(false);
                    });
                } else {
                    // 不弹
                    resolve(true);
                }
            })
        },
        // 最终创建订单
        /*async toCreateOrder() {
            let _this = this;

            _this.createOrder();
        },*/
        //提交低价精选机票订单前
        submitLowPrice() {
            // const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30'];
            return new Promise((resolve,reject)=> {
                //debugger
                let index = 0;
                let hasLowPrice = [];
                this.flightList.forEach(value => {
                    value.segmentList.forEach(value1 => {
                        if (value.productTag === 5) {
                            hasLowPrice.push(index);
                            index++;
                        }
                    })
                });
                if (hasLowPrice.length === 0) {
                    resolve(true);
                } else {
                    // 弹窗
                    let message = '';
                    this.ruleText.forEach((value, index) => {
                        if (hasLowPrice.indexOf(index) > -1) {
                            message += `本产品为低价精选产品，最晚于${value.timeLimitQueue}内出票，报销凭证提供${value.tagProperty}。\n`;
                            if (value.numberLimit) {
                                message += `人数限制:${value.numberLimit}\n`;
                            }
                            if (value.certificateLimit) {
                                message += `证件限制:${value.certificateLimit}\n`;
                            }
                            if (value.ageLimit) {
                                message += `年龄限制:${value.ageLimit}\n`;
                            }
                        }
                    });

                    this.$confirm(message, '温馨提示', {
                        confirmButtonText: '继续预订',
                        cancelButtonText: '重新选择价格',
                        type: 'warning'
                    }).then(() => {
                        resolve(true);
                    }).catch(() => {
                        reject(false);
                    });
                }
            })
        },
        // 最终创建订单
        async createOrder() {
            let _this = this;
            /*if (this.businessRuleControl === 2 && this.orderFreeApprove && !this.hasNodeList) {
                this.$message.warning('当前没有审批流，请联系管理员')
                return
            }*/
            this.loading = true;
            let checkResult = await this.$refs.evectionForm.checkForm();
            console.log('checkResult', checkResult);
            if (!checkResult.checkResult) {
                this.loading = false;
                return
            }
            if (!checkResult.formInfo.checkForm) {
                this.loading = false;
                const listLength = checkResult.formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!checkResult.formInfo.list[i].parameterForm) {
                        const text = `请填写${checkResult.formInfo.list[i].parameterText}`;
                        this.$message({
                            type: "warning",
                            message: text
                        });
                        break
                    }
                }

                return
            }
            clearInterval(this.searchTemp);
            let travelerInfo = this.$refs['travelerListForReserve'].getTravelerInfo();
            this.evectionParams.linkman = travelerInfo.linkman;
            this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone;
            this.evectionParams.smsType = travelerInfo.smsType;
            this.evectionParams.linkmanSms = travelerInfo.linkmanSms;
            this.evectionParams.userList = travelerInfo.userList;
            let hasChild = false;
            let hasDifferentAge = false;
            let hasDifferentAgeNameList = [];
            let hasNotCertificateType = false;
            this.evectionParams.userList.forEach(item=>{
                if (!item.certificateType) {
                    hasNotCertificateType = true;
                }
                if (item.age < 13) {
                    hasChild = true;
                }
                if (item.age < 22 || item.age > 65) {
                    hasDifferentAge = true;
                    hasDifferentAgeNameList.push(item.staffName);
                }
            });
            if (hasNotCertificateType) {
                this.loading = false;
                this.$message({
                    type: 'warning',
                    message: '请先添加出行人的证件'
                });
                return;
            }
            if (hasChild) {
                this.loading = false;
                this.$message({
                    type: 'warning',
                    message: '暂不支持在线预订儿童票'
                });
                return;
            }
            if (this.hasAgreementPricePro && hasDifferentAge) {
                this.loading = false;
                this.errorText = `协议价仅限乘客年龄22至65周岁预订，乘客${hasDifferentAgeNameList.join('，')}年龄不符合要求，请选择其他非协议价预订`;
                this.dialogError3Visible = true;
                return;
            }
            if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
                this.loading = false;
                this.$message({
                    type: 'warning',
                    message: '请先填写联系人'
                });
                return
            }

            let policyText = '';
            this.$refs['travelerListForReserve'].form.userList.forEach((item,index) => {
                let box = this.$refs['travelerListForReserve'].policyRestrictionsText(item);
                if (box && box.length != 0) {
                    box.forEach((BItem,BIndex) => {
                        policyText = `<p>${policyText}${BItem},</p>`;
                    });
                    policyText = `${policyText}<div>当前出行人<span style="color: red;">${item.name}</span>不符合条件</div>`;
                }
            });
            if (policyText != '') {
                this.$alert(`${policyText}`, '温馨提示', {
                    dangerouslyUseHTMLString: true
                });
                this.loading = false;
                return;
            }
            this.loading = false;
            // 票据提示
            let productRes = await this.productTipsShow();
            if (!productRes) {
                return
            }
            //预定低价精选机票弹出提示
            if (_this.submitLowPrice) {
                const [err, res] = await awaitWrap(_this.submitLowPrice());
                if (err === false) {
                    return
                }
            }
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            let timeTipsRes = await _this.timeLongTips(_this.$route.query.ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }
            let payRes = await _this.payTipsShow();
            if (!payRes) {
                return;
            }
            this.loading = true;
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                this.setSearchReact();
                this.loading = false;
                return
            }
            let [err, result] = await awaitWrap(consumer_journey_unityCreatePlaneTicket(
                {
                    evectionNo: this.evectionNo,
                    orderNo: this.orderNo,
                    pageType: 1,
                    reportConfiguration: checkResult.formInfo.list,
                    equityProductId: this.equityData == null ? null : this.equityData.productId,
                    equityProductPrice: this.equityData == null ? null : this.equityData.farePat,
                }
            ));
            if (err) {
                this.setSearchReact();
                // 200017 下单失败!机票订票失败:该航班变价，请重新搜索！
                this.loading = false;
                let orderResultList = err.data.datas ? err.data.datas.orderResultList : []
                let errorCode = err.data.code;
                this.errorText = err.data.msg;
                if (errorCode === '200017' || errorCode === '1000000' || errorCode === '200029' || errorCode === '300052') {
                    this.dialogError3Visible = true
                    /*this.dialogErrorVisible = true;
                    this.returnSecond = 5;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.returnToSearch()
                        }
                    }, 1000)*/
                } else {
                    this.dialogError2Visible = true
                }
                if (orderResultList) {
                    orderResultList.forEach(value => {
                        if (!value.isSuccess) {
                            let [...flightList] = this.flightList;
                            flightList.forEach(value1 => {
                                if (value1.journeyNo === value.orderNo) {
                                    value1.showError = true;
                                    value1.errorReason = value.desc
                                }
                            });
                            this.flightList = flightList
                        }
                    })
                }
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            let desc = ''
            let paymentPage = 0
            orderResult.orderResultList.forEach((value,index) => {
                if (index === 0) {
                    desc = value.desc
                    paymentPage = value.paymentPage
                }
                if (!value.isSuccess) {
                    canSubmit = false;
                    let [...flightList] = this.flightList;
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true;
                            value1.errorReason = value.desc
                        }
                    });
                    this.flightList = flightList
                }
            });
            if (canSubmit) {

                let controlRule; // 超标管控规则 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                if (this.violationResults.length > 0) {
                    controlRule = this.violationResults[0].controlRule;
                }
                const orderNo = orderResult.orderResultList[0].orderNo;
                // 保存所选保险
                let [iErr, iRes] = await awaitWrap(this.$refs.aInsureChoice.submit());
                if (iErr) {
                    this.loading = false
                    return;
                }
                this.loading = false
                //this.orderResult(result)
                if (paymentPage && desc.indexOf('机票预订成功') === -1) {
                    await this.noVipTips(desc);
                }
                // 判断是出差单审批还是预订审批    出差单审批1， 预订审批2
                if (this.businessRuleControl === 1) {
                    // 判断是走支付页面还是跳转完成页面
                    if (orderResult.isHaveOverproof && controlRule !== 1) {
                        // 有超标
                        this.$router.push({
                            name: 'admin-flight-reserve-result',
                            query: {
                                orderNo
                            }
                        })
                    } else {
                        // 无超标
                        this.$router.push({
                            name: 'admin-pay',
                            query: {
                                orderNos: orderNo,
                                evectionNo: this.evectionNo,
                                flightOrderList: orderNo,
                            }
                        })
                    }
                } else {
                    // 提交审批
                    // 跳转完成页面
                    if (this.orderFreeApprove || (orderResult.isHaveOverproof && controlRule !== 1)) {
                        // 需要审批 或者 有超标
                        this.$router.push({
                            name: 'admin-flight-reserve-result',
                            query: {
                                orderNo
                            }
                        })
                    } else {
                        // 免审且无超标
                        this.$router.push({
                            name: 'admin-pay',
                            query: {
                                orderNos: orderNo,
                                evectionNo: this.evectionNo,
                                flightOrderList: orderNo,
                            }
                        })
                    }
                }
            } else {
                this.setSearchReact()
                this.loading = false
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },
        noVipTips(desc) {
            return new Promise(resolve => {
                this.$alert(desc, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                       resolve();
                    }
                })
            })
        },
        returnToSearch() {
// this.detailFlight
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            this.$router.go(-1)
        },
        toCabinList() {
            this.dialogError3Visible = false;
            this.$router.go(-1);
        },
// 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams);
            params.evectionNo = this.evectionNo;
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
            if (err) {
                return false
            }
            return res
        },
        getTravelerInfo(param) {
            this.evectionParams.userList = param.userList;
            this.getAllPrice()
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++;
                //console.log('停留时间:', this.intervalNum)
                if (this.intervalNum >= 30 * 60) {
                    clearInterval(this.searchTemp);
                    this.$message({
                        duration: 5000,
                        type: "warning",
                        message: '页面停留时间超过30分钟，请您重新搜索航班'
                    });
                    this.intervalNum = 0;
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 3000)
                }
            }, 1000)
        },
        initInsure() {
            this.$refs.aInsureChoice.init({
                mock_data:{
                    //是否使用随机数据--true/false
                    enable: false,
                },
                //业务类型--1：机票/2：火车票
                businessType: 1,
                //订单号
                orderNo: this.orderNo,
                evectionType: this.evectionType,
                flightType: this.flightType,
            });
        },
        changeEnjoyMore(data) {
            this.equityData = data;
            this.getAllPrice();
        },
        changeInsure(data) {
            this.insuranceIdArr = data.id_list;
            this.getAllPrice();
        },
        async getOrderFreeApprove() {
            let res = await consumer_journey_orderFreeApprove({
                businessType: 1,
                orderNo: this.orderNo
            });
            this.orderFreeApprove = res.datas.orderFreeApprove
        },
        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        },
        showInsureSharePopup() {
            this.nowTime = moment().format('YYYY年MM月DD日');
            this.chooseInsureDialogVisible = true;
        },
        checkFreeProduct() {
            consumer_insurance_checkFreeProduct().then(res => {
                this.hasFreeInsureProduct = res.datas;
            })
        },
        queryPlaneNames(){
            const planeTypes = this.flightList[0].segmentList.map(e => {
                return e.planeType
            });
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.flightList[0].segmentList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName ? planeName : i.planeType;
                });
                this.$forceUpdate();
            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
        queryMeals() {
            let promises = [];
            for (let i = 0; i < this.flightList[0].segmentList.length; i++) {
                let val = this.flightList[0].segmentList[i];
                let param = {
                    flightNo: val.flightNo,
                    deptTime: val.depTime,
                    arrTime: val.arrTime
                };
                promises.push(consumer_flight_search_queryMeals(param));
            }
            if(promises.length>0){
                Promise.all(promises).then( (posts) =>{
                    for (let i = 0; i < this.flightList[0].segmentList.length; i++) {
                        let meals = posts[i].datas;
                        this.flightList[0].segmentList[i].hasMeals = meals? '有餐食':'无餐食';
                    }
                    // this.detail = detail;
                    this.$forceUpdate();
                }).catch(function(reason){
                    console.log('出错了',reason)
                });
            }
        },
        showRulePopup() {
            // 调接口打开规则弹窗
            this.ruleLoading = true;
            this.ruleText = [];
            this.ruleShow = true;
            let params = {
                rqData: []
            };
            this.flightList.forEach(value => {
                value.segmentList.forEach(value1=>{
                    const rqData = {
                        cabinCode: value1.cabinCode,
                        flightKey: value1.flightKey,
                        flightNo: value1.flightNo,
                        policyId: value1.policyId
                    };
                    params.rqData.push(rqData);
                    this.productTag = value1.productTag;
                })
            });
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                this.ruleLoading = false;
                let ruleText = res.datas.rsData || [];
                this.flightList.forEach(value=>{
                    value.segmentList.forEach(value2=>{
                        ruleText.forEach(value1 => {
                            if (value1.policyId === value2.policyId) {
                                value1.productTag = value2.productTag;
                                value1.depCityName = value2.depCityName;
                                value1.arrCityName = value2.arrCityName;
                            }
                        });
                    })
                });
                this.ruleText = ruleText;
            }).catch(()=>{
                this.ruleLoading = false;
            })
        },
        changeFlight() {
            let flightList = [];
            this.flightList.forEach(value=>{
                value.segmentList.forEach(value2=>{
                    flightList.push(value2);
                })
            });
            const back = `-${flightList.length}`;
            this.$router.go(parseInt(back));
        },
        showOrHideTips() {
            this.tipsHide = !this.tipsHide;
        }
    }
}
